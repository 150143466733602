import React from "react";
import { compose } from "recompose";

import { ROLES, EMPTY_RECORD } from "../constants";
import { fetchRecordFromLocalStorage } from "../helpers";

import Layout from "../components/layout";
import RecordAdmin from "../components/Record/RecordAdmin";
import {
  withAuthorization,
  withEmailVerification,
} from "../components/Account/Session";

const condition = (authUser) =>
  authUser && !!authUser.roles[ROLES.ADMIN];

const RecordAdminPage = compose(
  withEmailVerification,
  withAuthorization(condition),
)(RecordAdmin);

export default ({ location }) => {
  const { search, hash } = location;
  const recordId = search.substring(1);
  const record = fetchRecordFromLocalStorage({
    ...EMPTY_RECORD,
    id: recordId,
  });
  return (
    <Layout location={location}>
      <RecordAdminPage location={location} record={record} />
    </Layout>
  );
};
